"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patientenUmsaetzeColumnDefs = exports.ColumnGroup = void 0;
const behandlerService_1 = require("@/services/behandlerService");
const patientenUmsaetzeStore_1 = require("@/state/patientenUmsaetzeStore");
const common_ui_1 = require("@rose/common-ui");
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
var ColumnGroup;
(function (ColumnGroup) {
    ColumnGroup["standard"] = "standard";
    ColumnGroup["umsatzart"] = "umsatzart";
    ColumnGroup["abgerechnet"] = "abgerechnet";
    ColumnGroup["bezahlt"] = "bezahlt";
    ColumnGroup["goz"] = "goz";
    ColumnGroup["bema"] = "bema";
    ColumnGroup["umsatzsteuer"] = "umsatzsteuer";
})(ColumnGroup || (exports.ColumnGroup = ColumnGroup = {}));
const behandlerService = new behandlerService_1.BehandlerService();
exports.patientenUmsaetzeColumnDefs = [
    {
        headerName: 'Patient',
        headerTooltip: 'Patient',
        sortable: true,
        sort: 'asc',
        pinned: 'left',
        width: 300,
        comparator: common_ui_1.patientComparer,
        valueFormatter: params => params && (0, common_ui_1.patientDisplayText)(params.data),
        cellRendererSelector: (params) => {
            if (params.data.isSummary) {
                return { component: 'PatientenUmsaetzeSummary' };
            }
            return { component: 'PatientCell' };
        },
        suppressMovable: true,
        exportWidth: 180,
        columnGroup: ColumnGroup.standard,
    },
    {
        headerName: 'Termin',
        headerTooltip: 'Termin',
        field: 'folgetermine',
        cellClass: 'ag-cell-with-component',
        width: 30,
        sortable: true,
        resizable: true,
        comparator: common_ui_1.folgeTermineComparer,
        valueFormatter: common_ui_1.folgeTermineFormatter,
        cellRendererSelector: (params) => {
            var _a;
            if ((_a = params.data) === null || _a === void 0 ? void 0 : _a.isSummary) {
                return undefined;
            }
            return { component: 'TermineCell' };
        },
        exportWidth: 80,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.folgeTermineRawDateFormatter,
        useFormatterForExport: true,
        columnGroup: ColumnGroup.standard,
    },
    (0, common_ui_1.dateCol)('leistungvon', 'Datum von', ColumnGroup.standard, 100),
    (0, common_ui_1.dateCol)('leistungbis', 'Datum bis', ColumnGroup.standard, 100),
    // GROUP
    {
        headerName: 'Umsatzarten',
        headerTooltip: 'Leistungen nach Umsatzart und Gebührenordnung',
        children: [
            {
                headerName: 'Umsatz',
                headerTooltip: 'Umsatz, gesamt',
                children: [(0, common_ui_1.euroCol)('gesamt', 'Gesamt', ColumnGroup.umsatzart, 150)],
            },
            {
                headerName: 'Honorar',
                headerTooltip: 'Honorar-Leistungen',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('honorar', 'Gesamt', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('honorargoz', 'GOZ', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('honorarbema', 'BEMA', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('honorarerstattung', 'Erstattung', ColumnGroup.umsatzart, 80, false),
                ],
            },
            {
                headerName: 'Eigenlabor',
                headerTooltip: 'Eigenlabor-Leistungen',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('eigenlabor', 'Gesamt', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('eigenlaborleistungen', 'Leistungen', ColumnGroup.umsatzart, 100, false),
                    (0, common_ui_1.euroCol)('eigenlabormaterial', 'Material', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('eigenlaborbeb', 'BEB', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.euroCol)('eigenlaborbel', 'BEL', ColumnGroup.umsatzart, 80, false),
                ],
            },
            {
                headerName: 'Fremdlabor',
                headerTooltip: 'Fremdlabor-Leistungen',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('fremdlabor', 'Gesamt', ColumnGroup.umsatzart, 80, false),
                    (0, common_ui_1.txtCol)('fremdlabornamen', 'Labore', ColumnGroup.umsatzart, 80, false),
                ],
            },
            (0, common_ui_1.euroCol)('material', 'ZA Material', ColumnGroup.umsatzart, 80, false),
            (0, common_ui_1.euroCol)('huel', 'HU + EL', ColumnGroup.umsatzart, 80, false),
            (0, common_ui_1.percentCol)('ertragprozentual', 'Ertrag %', ColumnGroup.umsatzart, 80, false, true),
        ],
    },
    // GROUP
    {
        headerName: 'Abgerechnet',
        headerTooltip: 'In Rechnung gestellte Leistungen',
        children: [
            {
                headerName: 'Gesamt',
                headerTooltip: 'Gesamt',
                children: [(0, common_ui_1.euroCol)('lgesamt', '€', ColumnGroup.abgerechnet, 140)],
            },
            {
                headerName: 'Nicht Abgerechnet, gesamt',
                headerTooltip: 'Nicht abgerechnete Leistungen, gesamt',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('nagesamt', '€', ColumnGroup.abgerechnet, 100, false),
                    (0, common_ui_1.percentCol)('nagesamtanteil', '%', ColumnGroup.abgerechnet, 100, false),
                ],
            },
            {
                headerName: 'Nicht Abgerechnet, BEMA',
                headerTooltip: 'Nicht abgerechnete Leistungen, BEMA',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('nakasse', '€', ColumnGroup.abgerechnet, 100, false),
                    (0, common_ui_1.percentCol)('nakasseanteil', '%', ColumnGroup.abgerechnet, 100, false),
                ],
            },
            {
                headerName: 'Nicht Abgerechnet, GOZ',
                headerTooltip: 'Nicht abgerechnete Leistungen, GOZ',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('naprivat', '€', ColumnGroup.abgerechnet, 100, false),
                    (0, common_ui_1.percentCol)('naprivatanteil', '%', ColumnGroup.abgerechnet, 100, false),
                ],
            },
            {
                headerName: 'Abgerechnet, Gesamt',
                headerTooltip: 'Abgerechnete Leistungen, Gesamt',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('agesamt', '€', ColumnGroup.abgerechnet, 90, false),
                    (0, common_ui_1.percentCol)('agesamtanteil', '%', ColumnGroup.abgerechnet, 90, false),
                ],
            },
            {
                headerName: 'Abgerechnet, BEMA',
                headerTooltip: 'Abgerechnete Leistungen, BEMA',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('akasse', '€', ColumnGroup.abgerechnet, 75, false),
                    (0, common_ui_1.percentCol)('akasseanteil', '%', ColumnGroup.abgerechnet, 75, false),
                ],
            },
            {
                headerName: 'Abgerechnet, GOZ',
                headerTooltip: 'Abgerechnete Leistungen, GOZ',
                columnGroupShow: 'open',
                children: [
                    (0, common_ui_1.euroCol)('aprivat', '€', ColumnGroup.abgerechnet, 75, false),
                    (0, common_ui_1.percentCol)('aprivatanteil', '%', ColumnGroup.abgerechnet, 75, false),
                ],
            },
        ],
    },
    // GROUP
    {
        headerName: 'Bezahlt (Privatliquidation)',
        headerTooltip: 'Bezahlte Leistungen',
        children: [
            (0, common_ui_1.euroPercentColPair)('renichtbezahlt', 'Nicht bezahlt', ColumnGroup.bezahlt, 100, false, false),
            (0, common_ui_1.euroPercentColPair)('rebezahlt', 'Bezahlt', ColumnGroup.bezahlt, 70, false, false),
            (0, common_ui_1.euroPercentColPair)('reerlassen', 'Erlassen', ColumnGroup.bezahlt, 70, false, false),
            (0, common_ui_1.txtCol)('reanzahl', '# Rg', ColumnGroup.bezahlt, 120),
            (0, common_ui_1.txtCol)('renummern', 'RgNr', ColumnGroup.bezahlt, 200, false),
        ],
    },
    // GROUP
    {
        headerName: 'Behandler',
        children: [
            {
                headerName: 'Stammbehandler',
                field: 'stammbehandler',
                sortable: true,
                width: 150,
                resizable: true,
                valueFormatter: (0, common_ui_1.behandlerFormatter)(behandlerService),
            },
            {
                headerName: 'Leistungserbringer',
                field: 'leistungsErbringerVerteilung',
                width: 250,
                cellRendererParams: {
                    typ: 'patientenumsaetze',
                },
                cellRenderer: 'LeistungserbringerCell',
                valueFormatter: (0, common_ui_1.behandlerArrayFormatter)(behandlerService, v => v.behandler),
                resizable: true,
                exportWidth: 400,
            },
            {
                headerName: 'Honorar-Leistungserbringer',
                field: 'gesamt',
                width: 200,
                sortable: true,
                resizable: true,
                noexport: true,
                valueFormatter: common_ui_1.euroFormatterEmptyUndefined,
                valueGetter: (p) => {
                    const leistungsbehandler = patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.leistungserbringerSelected;
                    if (leistungsbehandler && p.data.leistungsErbringerVerteilung) {
                        return (0, lodash_1.chain)(p.data.leistungsErbringerVerteilung)
                            .filter((lev) => lev.behandler === leistungsbehandler.id)
                            .sumBy((e) => e.honorar)
                            .value();
                    }
                    else {
                        return undefined;
                    }
                },
                // pinnedRowCellRenderer: (p: any) => {
                //   if (p.data.honorarLeistungserbringer) {
                //     return '<span>' + `${Number(p.data.honorarLeistungserbringer).toFixed(1)}` + '€ </span>';
                //   }
                //   return '<span></span>';
                // },
            },
        ],
    },
    // GROUP
    {
        headerName: 'GOZ',
        headerTooltip: 'GOZ Leistungen nach Abrechnungsart',
        children: [
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZGesamt', 'Gesamt GOZ', ColumnGroup.goz, 100, true),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZA', 'A. Allgemeine zahnärztliche Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZB', 'B. Prophylaktische Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZC', 'C. Konservierende Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZD', 'D. Chirurgische Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZE', 'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZF', 'F. Prothetische Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZG', 'G. Kieferorthopädische Leistungen ', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZH', 'H. Eingliederung von Aufbißbehelfen und Schienen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZJ', 'J. Funktionsanalytische und funktionstherapeutische Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZK', 'K. Implantologische Leistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZL', 'L. Zuschläge', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZGAE', 'Ä. Leistungen nach GOÄ', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZV', 'V. Verlangensleistungen', ColumnGroup.goz, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.GOZGU', 'Ohne Zurechnung', ColumnGroup.goz, 80, false),
        ],
    },
    // GROUP
    {
        headerName: 'BEMA',
        headerTooltip: 'BEMA Leistungen nach Abrechnungsart',
        children: [
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMAGesamt', 'Gesamt BEMA', ColumnGroup.bema, 120, true),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMA1', 'Teil 1 - Konservierende und chirurgische Leistungen und Röntgenleistungen', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMA2', 'Teil 2 - Kieferbruch, Kiefergelenkserkrankungen', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMA3', 'Teil 3 - Kieferorthopädische Behandlung', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMA4', 'Teil 4 - Systematische Behandlung von Parodontopathien', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMA5', 'Teil 5 - Versorgung mit Zahnersatz und Zahnkronen', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMABAE', 'Ä. Leistungen nach GOÄ', ColumnGroup.bema, 80, false),
            (0, common_ui_1.euroCol)('leistungszurechnung.BEMABU', 'Ohne Zurechnung', ColumnGroup.bema, 80, false),
        ],
    },
    // GROUP
    {
        headerName: 'MwSt.',
        // headerTooltip: 'BEMA Leistungen nach Abrechnungsart',
        children: [
            (0, common_ui_1.euroCol)('gesamtnetto', 'GU netto', ColumnGroup.umsatzsteuer),
            (0, common_ui_1.euroCol)('gesamtmwst', 'GU MwSt', ColumnGroup.umsatzsteuer),
            (0, common_ui_1.euroCol)('gesamt', 'GU brutto', ColumnGroup.umsatzsteuer, 100),
            (0, common_ui_1.euroCol)('honorarnetto', 'HU netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('honorarmwst', 'HU MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('honorar', 'HU brutto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('materialnetto', 'Mat. netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('materialmwst', 'Mat. MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('material', 'Mat. brutto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabornetto', 'EL netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabormwst', 'EL MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabor', 'EL brutto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabormaterialnetto', 'EL Mat. netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabormaterialmwst', 'EL Mat. MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlabormaterial', 'EL Mat. brutto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('fremdlabornetto', 'FL netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlaborleistungennetto', 'EL Leist. netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlaborleistungenmwst', 'EL Leist. MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('eigenlaborleistungen', 'EL Leist. brutto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('fremdlabornetto', 'FL netto', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('fremdlabormwst', 'FL MwSt', ColumnGroup.umsatzsteuer, 80, false),
            (0, common_ui_1.euroCol)('fremdlabor', 'FL brutto', ColumnGroup.umsatzsteuer, 80, false),
        ],
    },
];
