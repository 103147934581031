"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.number.constructor.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var filters_1 = require("../../../common-ui/src/filters");

exports["default"] = (0, vue_1.defineComponent)({
  name: 'SpezialFilters',
  mixins: [filters_1.numeralFilter],
  props: {
    filters: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    displayedRowCount: {
      type: Number,
      "default": function _default() {
        return 0;
      }
    },
    displayedPatientCount: {
      type: Number,
      "default": function _default() {
        return 0;
      }
    },
    entryLabels: {
      type: Array,
      "default": function _default() {
        return ['Eintrag', 'Einträge'];
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    toggleFilterGroup: function toggleFilterGroup(filterKey) {
      this.$emit('toggleFilterGroup', filterKey);
    },
    filterGroupSelectedChanged: function filterGroupSelectedChanged(filterKey) {
      this.$emit('filterGroupSelectedChanged', filterKey);
    }
  }
});