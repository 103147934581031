"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadExcel = exports.setStoreFromParams = void 0;
const patientenUmsaetze_1 = require("@/components/columns/patientenUmsaetze");
const patientenUmsaetzeStore_1 = require("@/state/patientenUmsaetzeStore");
const teamsStore_1 = require("@/state/teamsStore");
const lodash_1 = require("lodash");
const base_1 = require("@rose/base");
const common_ui_1 = require("@rose/common-ui");
const base_2 = require("../../../base");
const exportExcel_1 = require("../../../common-ui/src/directImports/exportExcel");
const types_1 = require("@rose/types");
function setStoreFromParams(routeParams) {
    if (routeParams) {
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.leistungsbehandler) {
            patientenUmsaetzeStore_1.patientenUmsaetzeStore.commit.setLeistungserbringerSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.leistungsbehandler));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.stammbehandler) {
            patientenUmsaetzeStore_1.patientenUmsaetzeStore.commit.setTeamSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.stammbehandler));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.patientSearchInput) {
            patientenUmsaetzeStore_1.patientenUmsaetzeStore.commit.setPatientSearchInput(routeParams.patientSearchInput);
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.from) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.to)) {
            patientenUmsaetzeStore_1.patientenUmsaetzeStore.commit.setDate({
                startDate: routeParams.from,
                endDate: routeParams.to,
            });
            patientenUmsaetzeStore_1.patientenUmsaetzeStore.commit.setDateEnabled(true);
        }
    }
}
exports.setStoreFromParams = setStoreFromParams;
function downloadExcel() {
    var _a, _b;
    let wb = (0, exportExcel_1.createWorkBook)();
    let ws = (0, base_1.createEmptyWorkSheet)(wb, 'PatientenUmsaetze');
    let exportColumns = (0, lodash_1.reject)(patientenUmsaetze_1.patientenUmsaetzeColumnDefs, cd => !!cd.noexport);
    function countNestingDepth(columnDef) {
        if (!columnDef) {
            return 0;
        }
        let max = 0;
        for (const cd of columnDef) {
            if (cd.children) {
                let maxChildren = countNestingDepth(cd.children);
                if (maxChildren > max) {
                    max = maxChildren;
                }
            }
        }
        return max + 1;
    }
    let colCount = 0;
    let rowCount = 0;
    const maxNestingLevel = countNestingDepth(exportColumns);
    function printColumnHeaders(columnDef) {
        let nestingDepth = countNestingDepth(columnDef.children);
        (0, base_1.createCell)(ws, colCount, maxNestingLevel - 1 - nestingDepth, columnDef.headerName, types_1.Units.NONE, base_1.boldCellStyle);
        if (nestingDepth === 0) {
            colCount++;
        }
        if (columnDef.children) {
            for (const child of columnDef.children) {
                printColumnHeaders(child);
            }
        }
    }
    exportColumns.forEach(child => {
        printColumnHeaders(child);
    });
    // write data
    rowCount = maxNestingLevel - 1;
    let sortedRowData = (0, lodash_1.sortBy)(patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.patientenUmsaetzeFiltered, r => r.name);
    function collect(array, childKey) {
        return array
            .map(function (el) {
            if (childKey in el) {
                return collect(el[childKey], childKey);
            }
            else {
                return [el];
            }
        })
            .flat();
    }
    let leaves = collect(exportColumns, 'children');
    for (const row of sortedRowData) {
        colCount = 0;
        rowCount++;
        leaves.forEach(leave => {
            (0, base_1.createCell)(ws, colCount++, rowCount, (0, common_ui_1.getValueFromGrid)(row, leave), (0, common_ui_1.getUnitFromGrid)(leave));
        });
    }
    let metaData = {
        Filter: patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.patientSearchInput || '-',
        Stammbehandler: ((_a = patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.teamSelected) === null || _a === void 0 ? void 0 : _a.name) || '-',
        Leistungerbringer: ((_b = patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.leistungserbringerSelected) === null || _b === void 0 ? void 0 : _b.name) || '-',
    };
    if (patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.dateEnabled) {
        let selectedDateFormatted = `${(0, base_2.roseDayjs)(patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.date.startDate).format('DD.MM.YYYY')} - ${(0, base_2.roseDayjs)(patientenUmsaetzeStore_1.patientenUmsaetzeStore.getters.date.endDate).format('DD.MM.YYYY')}`;
        metaData['Datum-Filter'] = selectedDateFormatted;
    }
    (0, base_1.finalizeSimpleXLSXWithMetadata)(wb, ws, metaData, colCount, rowCount, (0, lodash_1.map)(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width || 80) })));
    (0, exportExcel_1.saveXLSX)(wb, 'patientenUmsaetze.xlsx');
}
exports.downloadExcel = downloadExcel;
