"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("../../../types");

var common_ui_1 = require("../../../common-ui");

var lodash_1 = require("lodash");

var behandlerService_1 = require("@/services/behandlerService");

exports["default"] = (0, vue_1.defineComponent)({
  filters: {
    behandler: function behandler(value) {
      return behandlerService_1.behandlerService.transform(value);
    }
  },
  props: {
    teams: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      "default": ''
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    teamSelected: {
      type: Object,
      "default": null
    },
    idSelected: {
      type: String,
      "default": null
    },
    onlySingles: Boolean
  },
  computed: {
    filteredTeams: function filteredTeams() {
      if (!this.teams) {
        return [];
      }

      if (this.onlySingles) {
        return (0, lodash_1.filter)(this.teams, function (team) {
          return team.typ === types_1.TeamTypes.SINGLE || team.typ === types_1.TeamTypes.BUNDLE;
        });
      }

      return this.teams;
    },
    selectedTeam: function selectedTeam() {
      var _this = this;

      var _a;

      return (_a = this.teamSelected) !== null && _a !== void 0 ? _a : this.teams.find(function (t) {
        return t.id === _this.idSelected;
      });
    }
  },
  methods: {
    onTeamSelected: function onTeamSelected(val) {
      this.$emit('team-selected', val);
    },
    teamIcon: function teamIcon(team) {
      return (0, common_ui_1.teamIconClass)(team);
    },
    teamTitle: function teamTitle(team) {
      return (0, lodash_1.chain)(team.mitglieder).compact().map(function (m) {
        return behandlerService_1.behandlerService.getProperty(m);
      }).join(', ');
    },
    clear: function clear(val) {
      console.log('Clear item: ', val);
    }
  }
});